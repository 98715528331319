import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default function IndexPageEn(props) {
  const lang = props.pageContext.langKey

  return (
    <div>
      <Layout isHome lang="sv">
        <Seo title="Startsida" />
        <div className="h-full w-full relative">
          <div className="w-full h-full">
            <div className="absolute h-full w-full ">
              <StaticImage
                alt="Background"
                src="../images/carbon-bg.jpg"
                objectFit="cover"
                className="h-full w-full"
                quality="100"
              />
            </div>
            <div className="relative h-full w-full flex flex-col flex-wrap items-center md:justify-center pt-16 lg:pt-0 z-50">
              <div className="container font-semibold intro-text text-xl md:text-2xl ">
                <div className="leading-relaxed">
                  <p>
                    Inom EU ETS har en ny period inletts, klimat står högt på
                    den politiska agendan, och priset för utsläppsrätter har
                    flerdubblats.
                  </p>
                  <p>
                    Bodecker Carbon – en del av Bodecker Partners – erbjuder
                    oberoende rådgivning, analys och specialiserad
                    portföljförvaltning av utsläppsrätter och länkade
                    instrument. Med unik kunskap och erfarenhet har vi följt
                    marknaden som ledande analytiker, riskmanagers och
                    finansiella handlare sedan EU ETS startade och vi har
                    hanterat flertalet stora portföljer åt energibolag och
                    industri.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}
